import user from "@/api/user"
import untils from "@/until/until"
import router from '@/router/router'
import {
  Toast
} from 'vant';
const wxBindOpenid = (obj, appid) => {
  return new Promise((resolve, reject) => {
    // 如果不是微信打开、提示错误
    if (untils.isWechat()) {
      let code = untils.getUrlParam("code");
      if (code) {
        let codeObj = {
          "code": code
        }
        user.bindopenid(codeObj, res => {
          if (res.code == 200) {
            resolve(res)
          } else {
            Toast({
              message: res.msg,
              position: 'bottom'
            });
            reject(res)
          }
        });
      } else {
        let url = encodeURIComponent(obj);
        window.location = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
          appid +
          "&redirect_uri=" +
          url +
          "&response_type=code&scope=snsapi_userinfo&state=bind_openid#wechat_redirect";
      }
    }
  })
}

export default wxBindOpenid
<template>
  <!-- 我的 -->
  <div class="my">
    <header>
      <div class="userInfo">
        <img :src="userInfo.headimgUrl">
        <p class="nickname">{{ userInfo.nickName ? userInfo.nickName : userInfo.phone }}</p>
        <div class="person" @click="$router.push('/my/profile')">个人主页
          <van-icon name="arrow"/>
        </div>
      </div>
      <ul class="incomeInfo" @click="$router.push('/my/income/list')">
        <li>
          <h3>{{ userInfo.balance + userInfo.zbalance || 0 }}</h3>
          <p>累计收益</p>
        </li>
        <li>
          <h3>{{ userInfo.zbalance + 0 || 0 }}</h3>
          <p>已获收益</p>
        </li>
        <li>
          <h3>{{ userInfo.balance + 0 || 0 }}</h3>
          <p>账户余额</p>
        </li>
      </ul>
    </header>
    <div class="myList">
      <van-cell title="绑定微信" is-link @click="bindWeChat(true)" :value="userInfo.openId && userInfo.openId != 'null' ? '已绑定' : '未绑定'">
        <template #icon>
          <img class="iconImg" :src="require('@/assets/img/my/can_yu@2x.png')"/>
        </template>
      </van-cell>

      <template v-for="(item,index) in myList">
        <van-cell :title="item.title" :key="index" is-link :url="item.url">
          <template #icon>
            <img class="iconImg" :src="item.icon"/>
          </template>
        </van-cell>
      </template>
    </div>

    <div class="img-logo">
      <img :src="logo" style="width: 53px;height: auto">
    </div>
  </div>
</template>

<script>
  import untils from "@/until/until"
  import wxLogin from '@/until/wxLogin.js'
  import wxBindOpenid from '@/until/wxBindOpenid.js'
  import {Toast} from "vant";

  export default {
    name: "my",
    data() {
      return {
        userInfo: {},
        logo: require('@/assets/img/my/buzzlogo@2x.png'),
        myList: [{
          title: '我的资料',
          icon: require('@/assets/img/my/zi_liao@2x.png'),
          url: '/my/profile'
        },
          //   {
          //   title: '我的参与',
          //   icon: require('@/assets/img/my/can_yu@2x.png'),
          //   url: ''
          // },
          {
            title: '帮助中心',
            icon: require('@/assets/img/my/bang_zhu@2x.png'),
            url: '/my/help'
          }, {
            title: '用户协议',
            icon: require('@/assets/img/my/xie_yi@2x.png'),
            url: '/my/privacy?isUser=true'
          }, {
            title: '隐私政策',
            icon: require('@/assets/img/my/yin_si@2x.png'),
            url: '/my/privacy'
          }, {
            title: '设置',
            icon: require('@/assets/img/my/setting.png'),
            url: '/my/setting'
          }, ]
      }
    },
    async mounted() {
      if (!localStorage.sessionKey && untils.isWechat()) {
        const res = await wxLogin.getWechatAppId()
        await wxLogin.wxLogin(window.location.href, res.data.appId)
      } else {
        let UserInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {}
        if (!UserInfo.phone || UserInfo.phone === null || UserInfo.phone === '') {
          // this.$router.push('/login?type=2')
          if (untils.isWechat()) {
            const res = await wxLogin.getWechatAppId()
            await wxLogin.wxLogin(window.location.href, res.data.appId)
          }
        }
      }

      this.getProfile()
    },
    methods: {
      async bindWeChat(checkOpenId) {
        if (this.userInfo.openId && this.userInfo.openId != 'null') {
          if (checkOpenId) {
            Toast('微信号已绑定')
          }
          return
        }

        if (untils.isWechat()) {
          // 这里需要改成实际逻辑：
          // 1. 微信授权 2. 绑定openId到微信
          const resData = await wxLogin.getWechatAppId()
          const res = await wxBindOpenid(window.location.href, resData.data.appId)
          if (res.code == 200) {
            this.userInfo = res.data
            Toast('绑定成功')
          } else {
            Toast("绑定失败，请稍后重试")
          }
        } else {
          Toast('请在"好求HaoQute"公众号内进行操作')
        }
      },
      getProfile() {
        this.$api.user.profile({}, async res => {
          this.userInfo = res.data

          if (this.$route.query.state == 'bind_openid' && this.$route.query.code) {
            await this.bindWeChat(false)
          }
        })
      },
    }
  }
</script>

<style lang="less" scoped>
  .my {
    width: 100%;
    background-color: #ffffff;
    min-height: 100vh;
    position: relative;

    header {
      width: 100%;
      height: 502px;
      background: url(~@/assets/img/my/my_bg.png) center top/cover no-repeat;
      padding: 62px 42px 0;

      .userInfo {
        display: flex;
        align-items: center;
        font-size: 36px;
        color: #FFFFFF;
        line-height: 50px;
        margin-bottom: 52px;

        img {
          width: 116px;
          height: 116px;
          border-radius: 50%;
          border: 2px solid #ffffff;
          overflow: hidden;
          margin-right: 18px;
          object-fit: cover;
        }

        .person {
          margin-left: auto;
          font-size: 28px;
          color: #FFFFFF;
          line-height: 40px;
          display: flex;
          align-items: center;
        }
      }

      .incomeInfo {
        display: flex;
        justify-content: space-between;
        padding: 0 62px;
        text-align: center;

        h3 {
          font-size: 32px;
          color: #FFFFFF;
          line-height: 42px;
          font-family: Bebas;
          margin-bottom: 14px;
        }

        p {
          font-size: 24px;
          color: #EEEEEE;
          line-height: 34px;
        }
      }
    }

    .myList {
      width: 100%;
      background: #FFFFFF;
      overflow: hidden;
      border-radius: 20px 20px 2px 2px;
      position: relative;
      top: -60px;
      padding: 27px 40px 0;

      .van-cell {
        height: 102px;
        align-items: center;

        .iconImg {
          width: 36px;
          height: 36px;
          margin-right: 28px;
        }
      }
    }

    .img-logo {
      //display: table-cell; /*主要是这个属性*/
      //position: absolute;
      vertical-align: middle;
      text-align: center;
      width: 100vw;
      padding-bottom: 5%;
      //bottom: 10%;
    }
  }
</style>
